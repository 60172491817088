@import url("https://fonts.googleapis.com/css?family=Roboto:400,400i,700");

* {
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
  box-sizing: border-box;
}

	.main-section {
    display: block;
    width: 640px;
    min-width: auto;
	margin:0 auto;
    padding: 20px;
    flex-direction: column;
    align-items: stretch;
    box-shadow: 0 0 20px -13px #000;
}

.card-container-main {
    box-sizing: border-box;
    background-size: cover;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 16px rgb(0 0 0 / 25%);
    border-radius: 7px;
    padding: 20px;
		height:280px;
}

.profile-image {
    height:132px;
    width: 132px;
    border-radius: 8px;
}

.card-personal-information{
    padding: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}
.card-name {
    font-weight: 700;
    font-size: 1.1em;
    color: #0f1e35;
}
.card-title {
        font-weight: 400;
    font-size: 0.8em;
    color: #0f1e35;
    line-height: 150%;
}

.main-logo {
    height: 50px;
    width: 50px;
    border-radius: 4px;
}

.card-lower-details {
    height: 100%;
    display: flex;
    flex-direction: column-reverse;

}
.card-info-item {
    font-weight: 400;
    font-size: 0.8em;
    line-height: 16px;
    align-items: center;
    color: #0f1e35;
}
.f600w{ font-weight:600;}
.form {
    padding: 20px 0;
}
.form-label {
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    color: #0F1E35;
	display: block;
}
#email-address {
    text-transform: lowercase;
}
.form-error-container {
    text-align: right;
    margin: 0;
}
.relative-position {
    position: relative;
}
.input-with-country {
    display: flex;
}
.country-form-picker {
    background: #f8f8f8;
    border: 0.5px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 5px;
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.v-10 {
    width: 10px;
}
.input-with-prefix {
    position: relative;
    width: 100%;
}
.input-prefix {
    width: 40px;
    text-align: center;
    position: absolute;
    height: 100%;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #0f1e35;
    justify-content: center;
}
.input-with-prefix .form-control{
    padding-left: 40px;
}
.country-form-flag {
    height: 30px;
    width: 30px;
}
.country-form-arrow {
    width: 10px;
    height: 6px;
}

.card-info-list{text-align:center;}
.send-value {
    display: flex;
    font-weight: 500;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 20px;
    background: #070783;
    border-radius: 5px;
    color: white;
    height: 40px;
    border: 0px;
    font-size: 15px;
    width: 30%;
	margin:0 auto;
}

.bg-light-bg{ background-color:#F6F4FB;}
.pd-10{ padding:10px;}

.card-main-container-teams{
    display: flex;
    min-height: 100px;
    justify-content: center;
    align-items: center;
    background-color: #0f1e35;
    text-align: left;
}
.w-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 940px;
}     

.cardcontainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.card {
    position: relative;
    display: flex;
    width: auto;
    min-height: 250px;
    min-width: 500px;
    margin-top: 80px;
    margin-bottom: 40px;
    justify-content: flex-start;
    align-items: center;
    border-top: 0px solid #000;
    border-right: 0px solid #000;
    border-radius: 15px;
    box-shadow: 0 0 40px -12px #000;
}          

.logodesktop {
    position: absolute;
    left: auto;
    top: 15px;
    right: 15px;
    bottom: auto;
    width: 100px;
    clear: none;
}
.profilephoto {
      position: relative;
    top: -50px;
    right: auto;
    bottom: 0%;
    overflow: hidden;
    width: 150px;
    height: 150px;
    border-style: solid;
    border-width: 3px;
    border-color: #fff;
    border-radius: 90px;
    box-shadow: 0 0 20px -8px #000;
}
.profilephoto img {
	width:100%;
}

.text {
    position: static;
    top: auto;
    right: auto;
    bottom: auto;
	text-align:center;
}

.text-block {
    font-family: 'Open Sans', sans-serif;
    font-size: 28px;
    line-height: 32px;
    font-weight: 800;
}
.text-block-2 {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    line-height: 20px;
}

.text-block-3 {
    margin-top: 5px;
    font-family: 'Open Sans', sans-serif;
}
.text-block-4 {
    margin-top: 5px;
    font-family: 'Open Sans', sans-serif;
}
.text-block-5 {
    margin-top: 5px;
    font-family: 'Open Sans', sans-serif;
}

.save-contact {
 position: relative;
    left: auto;
    top: 15px;
    /* right: -100px; */
    bottom: 0%;
    display: flex;
    width: 200px;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: #070783;
    font-family: 'Open Sans', sans-serif;
}
.link-block-cta {
    padding: 10px 20px;
    text-decoration: none;
}
.w-inline-block {
    max-width: 100%;
    display: inline-block;
}
.cta {
    color: #fff;
    font-size: 18px;
    font-weight: 800;
}

.notescontainer {
    display: flex;
    margin-bottom: 40px;
    justify-content: center;
    font-family: Ubuntu, Helvetica, sans-serif;
    font-size: 18px;
    line-height: 22px;
    font-weight: 300;
}
.notestext {
    display: flex;
    width: 400px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
}

.socialcontainer {
    display: flex;
    margin-bottom: 60px;
    justify-content: center;
}
.grid {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}
.w-layout-grid {
    display: -ms-grid;
    display: grid;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
    grid-row-gap: 16px;
    grid-column-gap: 16px;
}
.w-inline-block {
    max-width: 100%;
    display: inline-block;
	border-radius:100%;
	width:60px;
	height:60px;
}
.fa {
    padding: 15px;
    font-size: 30px !important;
    text-align: center;
    text-decoration: none;
    margin: 5px 2px;
	line-height:40px;
}

.fa:hover {
    opacity: 0.7;
}

.fa-facebook {
  background: #3B5998;
  color: white;
}

.fa-twitter {
  background: #55ACEE;
  color: white;
}

.fa-google {
  background: #dd4b39;
  color: white;
}

.fa-linkedin {
  background: #007bb5;
  color: white;
}

.fa-youtube {
  background: #bb0000;
  color: white;
}

.fa-instagram {
  background: #125688;
  color: white;
}

.fa-pinterest {
  background: #cb2027;
  color: white;
}

.fa-snapchat-ghost {
  background: #fffc00;
  color: white;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.fa-skype {
  background: #00aff0;
  color: white;
}

.fa-android {
  background: #a4c639;
  color: white;
}

.fa-dribbble {
  background: #ea4c89;
  color: white;
}

.fa-vimeo {
  background: #45bbff;
  color: white;
}

.fa-tumblr {
  background: #2c4762;
  color: white;
}

.fa-vine {
  background: #00b489;
  color: white;
}

.fa-foursquare {
  background: #45bbff;
  color: white;
}

.fa-stumbleupon {
  background: #eb4924;
  color: white;
}

.fa-flickr {
  background: #f40083;
  color: white;
}

.fa-yahoo {
  background: #430297;
  color: white;
}

.fa-soundcloud {
  background: #ff5500;
  color: white;
}

.fa-reddit {
  background: #ff5700;
  color: white;
}

.fa-rss {
  background: #ff6600;
  color: white;
}

.infoblockcontainer {
   max-height: none;
    min-height: 100px;
    padding-right: 60px;
    padding-bottom: 30px;
    margin-bottom: 20px;
    padding-left: 60px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-color: #070783;
}
.infoheader {
    display: flex;
    margin-top: 40px;
    margin-bottom: 20px;
    justify-content: center;
    font-family: 'Open Sans', sans-serif;
    color: #fff;
    font-size: 30px;
    line-height: 50px;
    font-weight: 700;
}
.video {
    padding-left: 0px;
}
.w-video {
   width: 100%;
    height: 400px;
}
.w-video iframe, .w-video object, .w-video embed {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.text-block-about {
    margin-bottom: 40px;
    font-family: 'Open Sans', sans-serif;
    color: #fff;
    font-size: 26px;
    line-height: 32px;
    text-decoration: none;
}
.buttonwhite {
    margin-bottom: 60px;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0 0 14px -3px #000;
    font-family: Ubuntu, Helvetica, sans-serif;
    color: #0f1e35;
    font-size: 18px;
    font-weight: 700;
}
.w-button {
    display: inline-block;
    padding: 9px 15px;
    background-color: #E47700;
    color: white;
    border: 0;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
    border-radius: 8px;
}
.text-block-about {
    margin-bottom: 40px;
    font-family: 'Open Sans', sans-serif;
    color: #fff;
    font-size: 26px;
    line-height: 32px;
	    text-align: center;
    text-decoration: none;
}
.link-block-url {
    display: block;
    text-decoration: none;
}

@media screen and (max-width: 479px){
.card-main-container-teams {
    padding-right: 10px;
    padding-left: 10px;
}
.card-main-container-teams {
    display: none;
}
.card {
    width: 100%;
    min-width: auto;
    margin-top: 120px;
    margin-bottom: 20px;
    padding-right: 10px;
    padding-left: 10px;
    flex-direction: column;
}
.infoblockcontainer {
    padding-right: 20px;
    padding-left: 20px;
}
.notestext {
    margin: 20px;
    font-family: Ubuntu, Helvetica, sans-serif;
    font-weight: 300;
    text-align: center;
}
}

.text-left{ text-align:left !important;}
.bg-light-gray {
    padding: 16px;
    background: rgb(249, 250, 251);
}
.box-shadow {
    background-color: rgb(255, 255, 255);
    color: rgb(34, 51, 84);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 10px;
    padding: 0px;
    box-shadow: rgb(159 162 191 / 18%) 0px 9px 16px, rgb(159 162 191 / 32%) 0px 2px 2px;
    width: 160px;
}
#feedback {
  max-width: 60%;
  width: 100%;
  margin: 30px auto;
  padding: 10px;
  border: solid 1px #f1f1f1;
  background: #fbfbfb;
  box-shadow: #e6e6e6 0 0 4px ;
  border-radius: 0.25rem;
}

.input-group-addon {
    width: 100px;
    padding: 2px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.25;
    color: #2e2e2e;
    text-align: center;
    background-color: #eceeef;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
}


.input-group-addon i {
	padding:2px; 
}

.font-15p{ font-size:20px !important;}
.heiht40p{ height:40px;}

/* roboto-cyrillic-ext-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/_next/static/media/roboto-cyrillic-ext-300-normal.cd7c5715.woff2) format('woff2'), url(/_next/static/media/roboto-all-300-normal.39add8fb.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}
/* roboto-cyrillic-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/_next/static/media/roboto-cyrillic-300-normal.88798412.woff2) format('woff2'), url(/_next/static/media/roboto-all-300-normal.39add8fb.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}
/* roboto-greek-ext-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/_next/static/media/roboto-greek-ext-300-normal.bc5ce703.woff2) format('woff2'), url(/_next/static/media/roboto-all-300-normal.39add8fb.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}
/* roboto-greek-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/_next/static/media/roboto-greek-300-normal.25dc89b0.woff2) format('woff2'), url(/_next/static/media/roboto-all-300-normal.39add8fb.woff) format('woff');
  unicode-range: U+0370-03FF;
}
/* roboto-vietnamese-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/_next/static/media/roboto-vietnamese-300-normal.b3d3e960.woff2) format('woff2'), url(/_next/static/media/roboto-all-300-normal.39add8fb.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}
/* roboto-latin-ext-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/_next/static/media/roboto-latin-ext-300-normal.37d4965d.woff2) format('woff2'), url(/_next/static/media/roboto-all-300-normal.39add8fb.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}
/* roboto-latin-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/_next/static/media/roboto-latin-300-normal.a4eae32d.woff2) format('woff2'), url(/_next/static/media/roboto-all-300-normal.39add8fb.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* roboto-cyrillic-ext-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/roboto-cyrillic-ext-400-normal.d7827ae3.woff2) format('woff2'), url(/_next/static/media/roboto-all-400-normal.2e9e9400.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}
/* roboto-cyrillic-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/roboto-cyrillic-400-normal.2d9c9d60.woff2) format('woff2'), url(/_next/static/media/roboto-all-400-normal.2e9e9400.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}
/* roboto-greek-ext-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/roboto-greek-ext-400-normal.2b547ded.woff2) format('woff2'), url(/_next/static/media/roboto-all-400-normal.2e9e9400.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}
/* roboto-greek-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/roboto-greek-400-normal.63e6dc18.woff2) format('woff2'), url(/_next/static/media/roboto-all-400-normal.2e9e9400.woff) format('woff');
  unicode-range: U+0370-03FF;
}
/* roboto-vietnamese-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/roboto-vietnamese-400-normal.c95fc061.woff2) format('woff2'), url(/_next/static/media/roboto-all-400-normal.2e9e9400.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}
/* roboto-latin-ext-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/roboto-latin-ext-400-normal.21abc8c8.woff2) format('woff2'), url(/_next/static/media/roboto-all-400-normal.2e9e9400.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}
/* roboto-latin-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/roboto-latin-400-normal.f2894edc.woff2) format('woff2'), url(/_next/static/media/roboto-all-400-normal.2e9e9400.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* roboto-cyrillic-ext-500-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/_next/static/media/roboto-cyrillic-ext-500-normal.a1b5c90d.woff2) format('woff2'), url(/_next/static/media/roboto-all-500-normal.d96daa81.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}
/* roboto-cyrillic-500-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/_next/static/media/roboto-cyrillic-500-normal.aa68ea54.woff2) format('woff2'), url(/_next/static/media/roboto-all-500-normal.d96daa81.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}
/* roboto-greek-ext-500-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/_next/static/media/roboto-greek-ext-500-normal.7ea6cffa.woff2) format('woff2'), url(/_next/static/media/roboto-all-500-normal.d96daa81.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}
/* roboto-greek-500-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/_next/static/media/roboto-greek-500-normal.533b03d2.woff2) format('woff2'), url(/_next/static/media/roboto-all-500-normal.d96daa81.woff) format('woff');
  unicode-range: U+0370-03FF;
}
/* roboto-vietnamese-500-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/_next/static/media/roboto-vietnamese-500-normal.7f8c0554.woff2) format('woff2'), url(/_next/static/media/roboto-all-500-normal.d96daa81.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}
/* roboto-latin-ext-500-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/_next/static/media/roboto-latin-ext-500-normal.85ebfb55.woff2) format('woff2'), url(/_next/static/media/roboto-all-500-normal.d96daa81.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}
/* roboto-latin-500-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/_next/static/media/roboto-latin-500-normal.3170fd9a.woff2) format('woff2'), url(/_next/static/media/roboto-all-500-normal.d96daa81.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* roboto-cyrillic-ext-700-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/_next/static/media/roboto-cyrillic-ext-700-normal.dd3651fb.woff2) format('woff2'), url(/_next/static/media/roboto-all-700-normal.ca3d0fdb.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}
/* roboto-cyrillic-700-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/_next/static/media/roboto-cyrillic-700-normal.258a358e.woff2) format('woff2'), url(/_next/static/media/roboto-all-700-normal.ca3d0fdb.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}
/* roboto-greek-ext-700-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/_next/static/media/roboto-greek-ext-700-normal.a8d16efd.woff2) format('woff2'), url(/_next/static/media/roboto-all-700-normal.ca3d0fdb.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}
/* roboto-greek-700-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/_next/static/media/roboto-greek-700-normal.432b858b.woff2) format('woff2'), url(/_next/static/media/roboto-all-700-normal.ca3d0fdb.woff) format('woff');
  unicode-range: U+0370-03FF;
}
/* roboto-vietnamese-700-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/_next/static/media/roboto-vietnamese-700-normal.72bf832f.woff2) format('woff2'), url(/_next/static/media/roboto-all-700-normal.ca3d0fdb.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}
/* roboto-latin-ext-700-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/_next/static/media/roboto-latin-ext-700-normal.6af98c24.woff2) format('woff2'), url(/_next/static/media/roboto-all-700-normal.ca3d0fdb.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}
/* roboto-latin-700-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/_next/static/media/roboto-latin-700-normal.71b2beb8.woff2) format('woff2'), url(/_next/static/media/roboto-all-700-normal.ca3d0fdb.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

